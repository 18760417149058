import * as React from "react"
import { Link } from "gatsby"
import "./styles.scss"

export default function Banner(props) {
    const { image, text, location, newWindow, newClass, isExternal } = props

    return (
        <div className={`banner ${newClass ? newClass : ''}`}>
            {!!location ?
                !!isExternal ? (
                    <a href={location} target={"_blank"} rel="noreferrer">
                        <img
                            src={image}
                            alt={text}
                        />

                        <div className="text">
                            <p>{text}</p>
                        </div>
                    </a>
                ) : (
                    <Link
                        to={location}
                        target={newWindow ? "_blank" : "_self"}
                    >
                        <img
                            src={image}
                            alt={text}
                        />

                        <div className="text">
                            <p>{text}</p>
                        </div>
                    </Link>
                )
                : null
            }

            {!location &&
                <>
                    <img
                        src={image}
                        alt={text}
                    />

                    <div className="text">
                        <p>{text}</p>
                    </div>
                </>
            }
        </div>
    )
}
