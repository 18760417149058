import React, { useEffect, useState } from "react"
import Layout from "../_components/Layout/Layout"
import Banner from "../_components/Banner/Banner"
import MainTitle from "../_components/MainTitle/MainTitle"
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn"
import InstaFeed from "../_components/InstaFeed/InstaFeed"
import Qr from "../_utils/Qr"

const Home = () => {
    const [show, setShow] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            let crtWrapper = document.querySelector(".crt-feed-window")
            if (!crtWrapper && window !== "undefined") {
                setShow(false)
            }
        }, 1200)
    }, [])

    return (
        <Layout title="Welcome">
            <div className="custom-bg home">
                <div className="bg">
                    <img src={"https://assets.xela.co/taste/taa/335a5869-3443-4739-8605-62f52e80b687.png"} alt="bg" />
                </div>
                <MainTitle>Welcome to <br /> Taste of London</MainTitle>

                <ClaimRewardBtn />
            </div>

            <div className="banners">
                <Banner
                    image="https://assets.xela.co/taste/taa/33dd567d-1180-406d-b1b1-8fb7c2d4aa26.png"
                    text="View all gifts and tasty treats"
                    location="/event-promotions"
                    newClass="yellow"
                />

                <Banner
                    image="https://assets.xela.co/taste/taa/f4a410b1-736f-4e80-a7bc-1e1acc29dcad.png"
                    text="Find your way around Taste"
                    location="/map-preview"
                    newWindow={true}
                />
                {!!Qr.getGameParams() &&
                    <Banner
                        image="https://assets.xela.co/taste/taa/405af968-ecae-472f-9272-74a3177b4ab5.png"
                        text={`Find your perfect dish`}
                        location="/suggest-menu"
                    />
                }
                <Banner
                    image="https://assets.xela.co/taste/taa/1c298dcc-542f-42e6-9527-e2ec8b5d1d3e.png"
                    text="Menus"
                    location="https://xela.co/s/taa-menus"
                    newWindow={true}
                    isExternal={true}
                />

                <Banner
                    image="https://assets.xela.co/taste/taa/ff48f782-7ec0-4bcf-bc37-db61ecaaa66b.png"
                    text="Things to Do"
                    location="https://xela.co/s/taa-things-to-do"
                    newWindow={true}
                    isExternal={true}
                />

                <Banner
                    image="https://assets.xela.co/taste/taa/c4565667-2f4a-404a-b136-c1f4845c65ed.png"
                    text="Win bigger with our Survey"
                    location="https://xela.co/s/taa-survey"
                    newWindow={true}
                    isExternal={true}
                />
            </div>

            {!!show && <InstaFeed />}
        </Layout>
    )
}

export default Home
