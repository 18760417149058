import React from "react"
import "./styles.scss"

export default function InstaFeed() {
    return (
        <div className="insta-feed-wrapper">
            <div id="curator-feed-default-feed-layout">
                <a href="https://curator.io"
                    rel="noreferrer"
                    target="_blank"
                    className="crt-logo crt-tag"
                >
                    Powered by Curator.io
                </a>
            </div>
            <div className="text">
                <p>View Instagram</p>
            </div>
        </div>
    )
}
