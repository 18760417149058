import React, { useEffect, useState } from "react"
import Qr from "../../_utils/Qr"
import StyledLink from "../StyledLink/StyledLink"

export default function ClaimRewardBtn({ text, className }) {
    const [showTbn, setShowTbn] = useState(true)

    useEffect(() => {
        if (!Qr.hasStoredParams()) {
            setShowTbn(false)
        }
    }, [])

    return !!showTbn && (
        <StyledLink rewardsButton to="/registration/" className={className}>
            {text ? text : "Unlock my gift"}
        </StyledLink>
    )
}
